import {getCookie} from '../../../util/appLocalStorage';

export function oauthIntegrationWindow({type}) {
  return new Promise((resolve) => {
    const url = `${process.env.VUE_APP_API_BASE_URL}/atsIntegration/${type}/oauth/?state=${getCookie('idToken')}`;
    const popup = window.open(url, '_blank', 'height=450,width=600');
    window.focus && popup.focus();

    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === 'oauthSuccess') {
        popup.close();
        resolve(event.newValue === 'true');
        window.localStorage.removeItem('oauthSuccess');
      }
    });
  });
}
