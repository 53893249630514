import {
  getAccountSeatsApi,
} from '../api/accountApi';
import {cancelPlanApi} from '../api/seatApi';
import {
  LOADING_STATE_SET,
  FETCH_SEATS_REQUEST,
  FETCH_SEATS_SUCCESS,
  CLEAR_SEATS_DATA,
  CANCEL_PLAN_REQUEST,
  FETCH_AUTH_DATA_REQUEST,
} from './storeActions';

const getInitialState = () => {
  return {
    seats: [],
    seatsLoaded: false,
    freeUsersLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  accountSeats: (state) => state.seats,
  accountSeatsLoaded: (state) => state.seatsLoaded,
  accountFreeUsersLoaded: (state) => state.freeUsersLoaded,
};

const actions = {
  [FETCH_SEATS_REQUEST]: async ({commit}, payload = {fromFlag: 'team'}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading seats'});
    try {
      const seats = await getAccountSeatsApi();
      if (Array.isArray(seats)) {
        commit(FETCH_SEATS_SUCCESS, {seats, payload});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CANCEL_PLAN_REQUEST]: async ({commit, dispatch}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Cancelling'});
    try {
      await cancelPlanApi();
      dispatch(FETCH_AUTH_DATA_REQUEST);
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_SEATS_SUCCESS]: (state, {seats, payload}) => {
    if (payload.fromFlag == 'team') {
      state = Object.assign(state, {seats, accountFreeUsersLoaded: true});
    } else {
      state = Object.assign(state, {seats, seatsLoaded: true});
    }
  },
  [CLEAR_SEATS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
