import api from './axios';

// Automations API
export const enableInterviewReminderApi = () => {
  return api.post(`/automation/enable/interviewReminder`);
};

export const disableInterviewReminderApi = () => {
  return api.post(`/automation/disable/interviewReminder`);
};

export const getInterviewRemindersApi = () => {
  return api.get('/automation/interviewReminders');
};

export const updateInterviewReminderByIdApi = ({payload, id}) => {
  return api.put(`/automation/${id}`, payload);
};
