import Vue from 'vue';
import moment from 'moment';

Vue.filter('firstLetterUpper', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('formatCalendarDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD, YYYY');
  }
});
