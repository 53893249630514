import api from './axios';

export const getCardApi = () => {
  return api.get('/payments/card');
};

export const updateCardApi = (payload) => {
  return api.put('/payments/card', payload);
};

export const getInvoicesApi = () => {
  return api.get('/payments/invoices');
};
