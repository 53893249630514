import api from './axios';

export const getAccountApi = () => {
  return api.get('/account');
};

export const putAccountApi = (payload) => {
  return api.put('/account', payload);
};

export const getAccountSeatsApi = () => {
  return api.get(`/account/getSeats`);
};

export const inviteUserApi = (payload) => {
  return api.post('/account/inviteUser', payload);
};

export const resendInvitationApi = (payload) => {
  return api.post('/account/resendInvitation', payload);
};

export const purchaseSeatsApi = (payload) => {
  return api.post('/account/purchaseSeats', payload);
};

export const removeSeatFromTeamMemberApi = (payload) => {
  return api.post('/account/removeSeatFromTeamMember', payload);
};

export const cancelSeatInvitation = (payload) => {
  return api.post('/account/cancelInvitation', payload);
};

export const getAccountAnalyticsApi = (date, endDate) => {
  return api.get(`/account/textAnalytics?date=${date}&enddate=${endDate}`);
};

export const deleteSeatFromAccountApi = (payload) => {
  return api.post('/account/removeSeat', payload);
};

export const updateToTexterApi = (payload) => {
  return api.post('/account/updateUserToTexter', payload);
};

export const updateToNonTexterApi = (payload) => {
  return api.post('/account/updateUserToNonTexter', payload);
};

export const updateTemplateSortOrder = (payload) => {
  return api.put('/account/updateTemplateSortOrder', payload);
};
