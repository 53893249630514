import { render, staticRenderFns } from "./RouteBreadcrumb.vue?vue&type=template&id=31209f70&scoped=true"
import script from "./RouteBreadcrumb.vue?vue&type=script&lang=js"
export * from "./RouteBreadcrumb.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31209f70",
  null
  
)

export default component.exports