export function addSecondsToCurrent(seconds) {
  const date = new Date();
  date.setSeconds(date.getSeconds() + seconds);
  return date.getTime();
}

export function isExpired(time) {
  return new Date() > new Date(parseInt(time));
}

// format mm yyyy
export function parseCardExpiryDate(month, year) {
  let expiryDate = '';
  if (month && year) {
    if (month < 10) {
      month = '0' + month;
    }
    expiryDate = month + year.toString().substr(2);
  }
  return expiryDate;
}
