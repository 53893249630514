import {
  getTeamTemplatesApi,
  createTeamTemplateApi,
  updateTeamTemplateApi,
  deleteTeamTemplateApi,
} from '@/api/templatesApi';

import {
  CREATE_TEAM_TEMPLATE_REQUEST,
  DELETE_TEAM_TEMPLATE_REQUEST,
  FETCH_TEAM_TEMPLATES_REQUEST,
  FETCH_TEAM_TEMPLATES_SUCCESS,
  UPDATE_TEAM_TEMPLATE_REQUEST,
  LOADING_STATE_SET,
  CLEAR_TEMPLATES_DATA,
} from './storeActions';

const getInitialState = () => {
  return {
    teamTemplates: [],
    teamTemplatesLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  teamTemplates: (state) => state.teamTemplates,
  teamTemplatesLoaded: (state) => state.teamTemplatesLoaded,
};

const actions = {
  [FETCH_TEAM_TEMPLATES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading team templates'});
    try {
      const teamTemplates = await getTeamTemplatesApi();
      if (!! teamTemplates) {
        commit(FETCH_TEAM_TEMPLATES_SUCCESS, {teamTemplates});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CREATE_TEAM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Creating team template'});
    try {
      const template = await createTeamTemplateApi(payload);
      if (!! template) {
        dispatch(FETCH_TEAM_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_TEAM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating team template'});
    try {
      const template = await updateTeamTemplateApi({payload, id});
      if (!! template) {
        dispatch(FETCH_TEAM_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_TEAM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Deleting team template'});
    try {
      const res = await deleteTeamTemplateApi(id);
      if (!! res) {
        dispatch(FETCH_TEAM_TEMPLATES_REQUEST);
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_TEAM_TEMPLATES_SUCCESS]: (state, {teamTemplates}) => {
    state = Object.assign(state, {teamTemplates, teamTemplatesLoaded: true});
  },
  [CLEAR_TEMPLATES_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
