import Vue from 'vue';
import VueRouter from 'vue-router';
import Multiguard from 'vue-router-multiguard';
import {
  dashboardRolesGuard,
  redirectAuthenticatedToDashboard,
  redirectUnauthenticated,
} from './guards';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  },
});

router.beforeEach(Multiguard([
  redirectUnauthenticated,
  redirectAuthenticatedToDashboard,
  dashboardRolesGuard],
));

export default router;
