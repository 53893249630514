import api from './axios';

export const updateUserCalendar = ({payload}) => {
  if (payload['calendarShortcut'] === true) {
    return api.post(`/calendarShortcut/enable`);
  } else {
    return api.post(`/calendarShortcut/disable`);
  }
};

export const createCalendarApi = ({payload}) => {
  return api.post(`/calendarShortcut`, payload);
};

export const getCalendarsApi = () => {
  return api.get(`/calendarShortcut`);
};

export const updateTemplateApi = ({payload, id}) => {
  return api.put(`/calendarShortcut/${id}`, payload);
};

export const deleteTemplateApi = (id) => {
  return api.delete(`/calendarShortcut/${id}`);
};
