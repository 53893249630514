import {
  getCardApi,
  getInvoicesApi,
  updateCardApi,
} from '@/api/paymentApi';
import {changePlanApi} from '@/api/seatApi';

import {
  LOADING_STATE_SET,
  FETCH_CREDIT_CARD_REQUEST,
  FETCH_CREDIT_CARD_SUCCESS,
  FETCH_PAYMENTS_DATA_REQUEST,
  PURCHASE_PLAN_REQUEST,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  CLEAR_PAYMENTS_DATA,
  FETCH_AUTH_DATA_REQUEST,
  UPDATE_CREDIT_CARD_REQUEST,
} from './storeActions';

const getInitialState = () => {
  return {
    creditCard: null,
    creditCardLoaded: false,
    invoices: [],
    invoicesLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  creditCard: (state) => state.creditCard,
  creditCardName: (state) => state.creditCard && state.creditCard.name,
  creditCardBrand: (state) => state.creditCard && state.creditCard.brand,
  creditCardExpMonth: (state) => state.creditCard && state.creditCard.expMonth,
  creditCardExpYear: (state) => state.creditCard && state.creditCard.expYear,
  creditCardLast4: (state) => state.creditCard && state.creditCard.last4,
  creditCardLoaded: (state) => state.creditCardLoaded,
  invoices: (state) => state.invoices,
  invoicesLoaded: (state) => state.invoicesLoaded,
};

const actions = {
  [FETCH_PAYMENTS_DATA_REQUEST]: async ({commit, dispatch}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading payment info'});
    try {
      await dispatch(FETCH_CREDIT_CARD_REQUEST);
      await dispatch(FETCH_INVOICES_REQUEST);
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_CREDIT_CARD_REQUEST]: async ({commit}, payload = {turnOffLoader: false}) => {
    try {
      const creditCard = await getCardApi();
      if (creditCard) {
        commit(FETCH_CREDIT_CARD_SUCCESS, {creditCard});
      }
    } catch (e) { } finally {
      payload.turnOffLoader && commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_CREDIT_CARD_REQUEST]: async ({commit, dispatch}, payload) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Update payments data'});
    try {
      const creditCard = await updateCardApi(payload);
      if (creditCard) {
        dispatch(FETCH_CREDIT_CARD_REQUEST, {turnOffLoader: true});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_INVOICES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading'});
    try {
      const invoices = await getInvoicesApi();
      if (invoices) {
        commit(FETCH_INVOICES_SUCCESS, {invoices});
      }
    } catch (e) { }
  },
  [PURCHASE_PLAN_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Purchasing'});
    try {
      const res = await changePlanApi(payload);
      !!res && await dispatch(FETCH_AUTH_DATA_REQUEST);
      return true;
    } catch (e) {
      return false;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_CREDIT_CARD_SUCCESS]: (state, {creditCard}) => {
    state = Object.assign(state, {creditCard, creditCardLoaded: true});
  },
  [FETCH_INVOICES_SUCCESS]: (state, {invoices}) => {
    state = Object.assign(state, {invoices, invoicesLoaded: true});
  },
  [CLEAR_PAYMENTS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
