<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      v-on:close="displayToggle(false)"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
    >
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">Get a number</h5>
      </template>
      <div class="mt-2 mb-5">By getting a number you will fill one of your available seats.</div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary border-0"
          @click.prevent="displayToggle(false)"
        >
          Back
        </button>
        <button class="btn btn-primary" type="submit">
          <router-link :to="{name: 'Get Number'}">Get number</router-link>
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import {INVITE_USER_REQUEST} from '@/store/storeActions';

export default {
  data() {
    return {
      display: false,
      email: '',
    };
  },
  methods: {
    ...mapActions(
        [INVITE_USER_REQUEST],
    ),
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
    },
    submit: function() {
      this.INVITE_USER_REQUEST({
        email: this.email,
      });
      this.displayToggle(false);
    },
  },
};
</script>
