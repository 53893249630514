import api from './axios';

export const loginApi = ({email, password}) => {
  return api.post('/auth/login', {email, password});
};

export const signupApi = (payload) => {
  return api.post('/auth/signup', payload);
};

export const resetPasswordWithEmailApi = ({email}) => {
  return api.post('/auth/sendResetPasswordEmail', {email});
};

export const resetPasswordUsingCodeApi = ({email, code, newPassword}) => {
  return api.post('/auth/resetPasswordUsingCode', {email, code, newPassword});
};

export const logoutApi = () => {
  return api.post('/auth/logout');
};
