import {
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  CHANGE_PLANS_COMPONENT_SCREEN,
  CLEAR_PLANS_DATA,
} from './storeActions';
import {accountTypes, plansTypes} from './userConstants';
import {
  PLAN_SELECTION_SCREEN,
} from '@/components/App/User/SelectPlan/planSelectionScreensNames.js';
import {getPlans} from '@/api/planApi';

const getInitialState = () => {
  return {
    plans: [
      {
        title: 'Pay As You Go',
        accountType: accountTypes.PERSONAL,
        planType: plansTypes.PAYG,
        description: 'Top up with text packages that carry forward each month',
        subDescription: 'Ideal for light texters and coordinators',
        requirements: [
          {
            title: 'Credit card required',
            planType: plansTypes.MONTHLY,
          }],
        monthlyPrice: 15,
        features: [
          'Credit card required',
          'Create and use messaging templates',
          'Start texting campaigns',
        ],
      },
      {
        title: 'Personal Unlimited',
        accountType: accountTypes.PERSONAL,
        description: 'Send and receive unlimited text messages each month',
        subDescription: 'A robust texting plan just for you',
        requirements: [
          {
            title: 'Credit card required',
            planType: plansTypes.MONTHLY,
          },
          {
            title: 'Credit card required',
            planType: plansTypes.ANNUAL,
          }],
        monthlyPrice: 0,
        annualPrice: 0,
        features: [
          'Send as many texts as you want',
          'Create and use messaging templates',
          'Start texting campaigns',
        ],
      },
      {
        title: 'Team Unlimited',
        accountType: accountTypes.TEAM,
        description: 'Manage your team members with centralized billing',
        subDescription: 'Minimum team size of 3 recruiters',
        requirements: [
          {
            title: 'Credit card required',
            planType: plansTypes.MONTHLY,
          },
          {
            title: 'Invoicing available',
            planType: plansTypes.ANNUAL,
          },
        ],
        monthlyPrice: 0,
        annualPrice: 0,
        features: [
          'Send as many texts as you want',
          'Create and use messaging templates',
          'Start texting campaigns',
          'Manage your teams',
        ],
        clarification: 'Per seat',
      },
    ],
    plansLoaded: false,
    plansComponentScreen: PLAN_SELECTION_SCREEN,
  };
};

const state = getInitialState();

const getters = {
  plans: (state) => state.plans,
  plansLoaded: (state) => state.plansLoaded,
  plansComponentScreen: (state) => state.plansComponentScreen,
  currentPlan: ({plans}, {accountType, planType}) => {
    let plan = null;
    if (accountType && planType) {
      plan = (planType === plansTypes.PAYG) ? plans[0] :
        (accountType === accountTypes.PERSONAL) ? plans[1] :
          (accountType === accountTypes.TEAM) ? plans[2] : null;
    }
    return plan;
  },
  isPaygPlan: (state, getters) => {
    return getters.currentPlan ?
      getters.currentPlan.planType === plansTypes.PAYG :
      false;
  },
};

const actions = {
  [FETCH_PLANS_REQUEST]: async ({commit}) => {
    try {
      const planPrices = await getPlans();
      commit(FETCH_PLANS_SUCCESS, {planPrices});
    } catch (e) {
    }
  },
};

const mutations = {
  [FETCH_PLANS_SUCCESS]: (state, {planPrices}) => {
    const plans = [...state.plans];
    planPrices.forEach((planItem) => {
      const planIndex = plans.findIndex(
        (p) => p.title === planItem.title.trim());
      if (planIndex >= 0) {
        const plan = {...plans[planIndex]};
        plan.monthlyPrice = planItem.monthlyPrice;
        plan.annualPrice = planItem.annualPrice;
        plans[planIndex] = plan;
      }
    });
    state = Object.assign(state, {plans, plansLoaded: true});
  },
  [CHANGE_PLANS_COMPONENT_SCREEN]: (
    state, data = {screen: PLAN_SELECTION_SCREEN}) => {
    state = Object.assign(state, {plansComponentScreen: data.screen});
  },
  [CLEAR_PLANS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
