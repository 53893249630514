<template>
  <div>
    <notifications></notifications>
    <router-view></router-view>
    <loader></loader>
  </div>
</template>

<script>
import Loader from '/src/components/App/UI/Loader';

export default {
  components: {
    Loader,
  },
};
</script>
