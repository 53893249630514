<template>
  <router-view></router-view>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {isExpired} from '@/util/appTime';
import {
  FETCH_AUTH_DATA_REQUEST,
  LOGOUT_REQUEST,
} from '@/store/storeActions';

export default {
  methods: {
    ...mapActions([
      FETCH_AUTH_DATA_REQUEST,
    ]),
  },
  computed: {
    ...mapGetters({
      userId: 'userId',
    }),
  },
  created() {
    if (!this.userId) {
      const expiredTime = localStorage.getItem('expiresIn');
      if (!expiredTime || isExpired(expiredTime)) {
        this.$store.dispatch(LOGOUT_REQUEST, {onServer: false});
      } else {
        this.FETCH_AUTH_DATA_REQUEST();
      }
    }
  },
};
</script>
