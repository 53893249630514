export function setStorageItems(storageItems) {
  for (const [key, value] of Object.entries(storageItems)) {
    localStorage.setItem(key, value);
  }
}

export function removeStorageItems(storageItems) {
  if (Array.isArray(storageItems)) {
    storageItems.forEach((item) => localStorage.removeItem(item));
  } else {
    localStorage.removeItem(storageItems);
  }
}

export function setCookie(key, value, expires) {
  document.cookie = key + '=' + value + '; expires=' +
    new Date(expires).toUTCString() + '; path=/';
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function deleteCookie(key) {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
}
