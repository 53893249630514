import {
  createOnboardingMessageApi,
  deleteOnboardingMessageByIdApi, disableOnboardingMessageApi, enableOnboardingMessageApi,
  getOnboardingMessagesApi,
  updateOnboardingMessageByIdApi,
} from '@/api/onboardingApi';

import {
  CLEAR_ONBOARDINGS_DATA,
  CREATE_USER_ONBOARGING_REQUEST,
  DELETE_ONBOARD_TEMPLATE_REQUEST,
  FETCH_ONBOARDING_SUCCESS,
  FETCH_USER_ONBOARDING_REQUEST,
  LOADING_STATE_SET,
  UPDATE_TEMPLATE_ONBOARDING_REQUEST,
  UPDATE_USER_ONBOARDING_REQUEST,
} from './storeActions';
import appNotification from '../util/appNotification';

const getInitialState = () => {
  return {
    onboardings: [],
    onboardingLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  onboardings: (state) => state.onboardings,
  onboardingLoaded: (state) => state.onboardingLoaded,
};

const actions = {
  [UPDATE_USER_ONBOARDING_REQUEST]: async ({commit}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: false, loaderText: 'Updating user onboarding'});
    try {
      if (payload.enableOnboarding) {
        return await enableOnboardingMessageApi();
      } else {
        return await disableOnboardingMessageApi();
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CREATE_USER_ONBOARGING_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Creating onboard template'});
    try {
      const template = await createOnboardingMessageApi({payload});
      if (!! template) {
        dispatch(FETCH_USER_ONBOARDING_REQUEST);
        const message = 'Template Created successfully!';
        appNotification.notify({message});
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_USER_ONBOARDING_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading onboarding templates'});
    try {
      const onboardings = await getOnboardingMessagesApi();
      if (!! onboardings) {
        commit(FETCH_ONBOARDING_SUCCESS, {onboardings});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_TEMPLATE_ONBOARDING_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating onboard template'});
    try {
      const template = await updateOnboardingMessageByIdApi({payload, id});
      if (!! template) {
        dispatch(FETCH_USER_ONBOARDING_REQUEST);
        const message = 'Template Updated successfully!';
        appNotification.notify({message});
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_ONBOARD_TEMPLATE_REQUEST]: async ({commit, dispatch}, {id}) => {
    commit(LOADING_STATE_SET, {isLoading: false, loaderText: 'Deleteing user onboarding'});
    try {
      const result = await deleteOnboardingMessageByIdApi(id);
      if (!! result) {
        dispatch(FETCH_USER_ONBOARDING_REQUEST);
      }
      return result;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [CLEAR_ONBOARDINGS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
  [FETCH_ONBOARDING_SUCCESS]: (state, {onboardings}) => {
    state = Object.assign(state, {onboardings, onboardingLoaded: true});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
