import api from './axios';

export const getAtsIntegrationApi = () => {
  return api.get('/atsIntegration');
};

export const updateAtsIntegrationApi = (payload) => {
  return api.put('/atsIntegration', payload);
};

export const atsIntegrationTestConnectionApi = (payload) => {
  return api.post('/atsIntegration/testConnection', payload);
};

export const deleteAtsIntegrationApi = () => {
  return api.delete('/atsIntegration');
};
