import {
  disableInterviewReminderApi,
  enableInterviewReminderApi,
  getInterviewRemindersApi,
  updateInterviewReminderByIdApi,
} from '@/api/interviewReminderApi';

import {
  CLEAR_INTERVIEW_REMINDERS_DATA,
  FETCH_AUTH_DATA_REQUEST,
  FETCH_INTERVIEW_REMINDERS_REQUEST,
  FETCH_INTERVIEW_REMINDERS_SUCCESS,
  LOADING_STATE_SET,
  UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST,
  UPDATE_USER_INTERVIEW_REMINDER_REQUEST,
} from './storeActions';

const getInitialState = () => {
  return {
    interviewReminders: [],
    interviewReminderLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  interviewReminders: (state) => state.interviewReminders,
  interviewReminderLoaded: (state) => state.interviewReminderLoaded,
};

const actions = {
  [FETCH_INTERVIEW_REMINDERS_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading interview reminders templates'});
    try {
      const interviewReminders = await getInterviewRemindersApi();
      if (!!interviewReminders) {
        commit(FETCH_INTERVIEW_REMINDERS_SUCCESS, {interviewReminders});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating interview reminders template'});
    try {
      const template = await updateInterviewReminderByIdApi({payload, id});
      if (!!template) {
        dispatch(FETCH_INTERVIEW_REMINDERS_REQUEST);
        dispatch(FETCH_AUTH_DATA_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_USER_INTERVIEW_REMINDER_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: false, loaderText: 'Updating user reminder'});
    try {
      if (payload.enableInterviewReminder) {
        return await enableInterviewReminderApi();
      } else {
        return await disableInterviewReminderApi();
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_INTERVIEW_REMINDERS_SUCCESS]: (state, {interviewReminders}) => {
    state = Object.assign(state, {interviewReminders, interviewReminderLoaded: true});
  },
  [CLEAR_INTERVIEW_REMINDERS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
