export const notificationsTypes = {
  default: {
    title: 'default',
  },
  info: {
    title: 'info',
    icon: 'ni ni-bell-55',
  },
  primary: {
    title: 'primary',
  },
  danger: {
    title: 'danger',
  },
  warning: {
    title: 'warning',
  },
  success: {
    title: 'success',
  },
};

const appNotification = (function() {
  let _notifyObj;

  function _init(notify) {
    _notifyObj = notify;
  }

  function _notify({title, message, type = notificationsTypes.info}) {
    _notifyObj((
      {
        title,
        message,
        type: type.title,
        icon: type.icon,
      }));
  }

  return {
    init: _init,
    notify: _notify,
  };
})();

export default appNotification;
