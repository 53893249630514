import {
  buyTextPackageApi,
} from '../api/seatApi';
import appNotification from '../util/appNotification';
import {
  BUY_TEXT_PACKAGE_REQUEST, LOADING_STATE_SET,
} from './storeActions';

const getInitialState = () => {
  return {
    textPackagesPrices: [
      {
        count: 200,
        price: 16,
      },
      {
        count: 400,
        price: 28,
      },
      {
        count: 600,
        price: 36,
      },
      {
        count: 800,
        price: 40,
      },
      {
        count: 1000,
        price: 45,
      },
    ],
    currentPackage: null,
    textPackagesPricesLoaded: true,
  };
};

const state = getInitialState();

const getters = {
  textPackagesPrices: (state) => state.textPackagesPrices,
  textPackagesPricesLoaded: (state) => state.textPackagesPricesLoaded,
};

const actions = {
  [BUY_TEXT_PACKAGE_REQUEST]: async ({commit}, payload) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Purchasing package'});
    try {
      const res = await buyTextPackageApi(payload);
      res && appNotification.notify({title: 'Text package successfully purchased'});
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

export default {
  state,
  getters,
  actions,
};
