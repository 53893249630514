import api from './axios';

export const enableOnboardingMessageApi = () => {
  return api.post(`/automation/enable/onboardingMessage`);
};

export const disableOnboardingMessageApi = () => {
  return api.post(`/automation/disable/onboardingMessage`);
};

export const getOnboardingMessagesApi = () => {
  return api.get(`/automation/onboardingMessages`);
};

export const createOnboardingMessageApi = ({payload}) => {
  return api.post(`/automation`, payload);
};

export const updateOnboardingMessageByIdApi = ({payload, id}) => {
  return api.put(`/automation/${id}`, payload);
};

export const deleteOnboardingMessageByIdApi = (id) => {
  return api.delete(`/automation/${id}`);
};
