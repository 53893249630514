export const phoneNumberRule = {
  message: () => 'Must be a valid phone number',
  validate(value) {
    return new Promise((resolve) => {
      const re = /^\([0-9]{3}\)\s?([0-9]{3})-([0-9]{4})$/;
      resolve({valid: re.test(value)});
    });
  },
};

export const creditCardRule = {
  message: () => `Card number must be 16 digits`,
  validate(value) {
    value = value.replace(/\s+/g, '');
    return new Promise((resolve) => {
      const re = /^([0-9]{16})$/;
      resolve({valid: re.test(value)});
    });
  },
};

export const cardExpiredRule = {
  message: () => 'Your card is alredy expired',
  validate(value) {
    return new Promise((resolve) => {
      let valid = false;
      if (value.length === 4) {
        const date = new Date(`20${value.substr(2, 2)}`, value.substr(0, 2));
        date.setDate(date.getDate() - 1);
        const currentDate = new Date();
        if (date >= currentDate) {
          valid = true;
        }
      }
      resolve({valid});
    });
  },
};

export const fullNameRule = {
  message: () => `Full name must consist of first and last name`,
  validate(value) {
    return new Promise((resolve) => {
      const valArr = value.split(' ');
      resolve({valid: Array.isArray(valArr) && valArr.length > 1 && valArr[1]});
    });
  },
};

export const isBusyRule = {
  message: (field) => `${field} already used`,
  validate(value, busyValues) {
    return new Promise((resolve) => {
      resolve({valid: !busyValues.includes(value)});
    });
  },
};
